import "babel-polyfill";

window._ = require("lodash");
window.$ = window.jQuery = require("jquery");
window.Popper = require("@popperjs/core");
window.moment = require("moment");

window.Pace = require("pace-js/pace.js");

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Datatables
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-buttons");
require("datatables.net-buttons-bs4");
require("datatables.net-responsive");
require("datatables.net-responsive-bs4");
require("datatables.net-fixedheader-bs4");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-buttons/js/buttons.html5");

$.extend($.fn.dataTable.defaults, {
    autoWidth: false,
    responsive: true,
    language: {
        emptyTable: "No data available",
    },
});

window.bootstrap = require("bootstrap");
require("chosen-js");
require("chosen-readonly");
require("@fortawesome/fontawesome-free/js/all");
require("bootstrap-datepicker");
require("devbridge-autocomplete");


